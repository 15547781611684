<template>
  <aside
    class="creation-sidebar"
    id="creation-sidebar"
    :class="{ 'creation-sidebar--opened': opened }"
  >
    <h2 class="creation-sidebar__title">
      How your {{ sidebarTitle }} will look like
    </h2>
    <p class="creation-sidebar__text">
      You can always change your template later.
    </p>

    <div class="creation-sidebar-inner">
      <div class="sidebar-preview-wrap">
        <PreviewChanges
          :preview="preview"
          :loading="isLoadingPreview"
          @openPrevModal="openPreviewModal"
          @closePrevModal="closePreviewModal"
        />
      </div>

      <button class="close-sidebar-btn" @click.prevent="$emit('closeSidebar')">
        <AppIcon componentName="CloseIcon" />
      </button>
    </div>

    <div class="sidebar-nav" v-if="showButton">
      <AppButton
        :title="navBtnTitles.next"
        className="primary"
        class="sidebar-nav__item"
        @click.prevent="goNextStep"
      />
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/helpers/event-bus";
import {
  CREATING_RESUME_NAMES,
  CREATING_COVER_LETTER_NAMES,
} from "@/helpers/rout-constants";
import PreviewChanges from "@/components/creation/PreviewChanges";

export default {
  name: "SidebarResumeCreation",
  components: {
    PreviewChanges,
  },

  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    switch (this.$route.name) {
      case CREATING_COVER_LETTER_NAMES.STEP_1:
        this.showButton = false;
        break;
      case CREATING_COVER_LETTER_NAMES.STEP_2:
        this.showButton = false;
        break;
      default:
        this.showButton = false;
        break;
    }
  },

  computed: {
    ...mapGetters({
      previewResumeAll: "resumeCreation/getResumePreviewAll",
      previewLetterAll: "coverLetterCreation/getCoverLetterPreviewAll",
      isLoadingPreviewResume: "resumeCreation/isLoadingPreview",
      isLoadingPreviewLetter: "coverLetterCreation/isLoadingPreview",
    }),

    isResume() {
      return Object.values(CREATING_RESUME_NAMES).includes(this.$route.name);
    },

    preview() {
      return this.isResume ? this.previewResumeAll : this.previewLetterAll;
    },

    isLoadingPreview() {
      return this.isResume
        ? this.isLoadingPreviewResume
        : this.isLoadingPreviewLetter;
    },

    progressPercent() {
      return this.$route.meta.percentProgress;
    },

    sidebarTitle() {
      return this.isResume ? "resume" : "cover letter";
    },

    navBtnTitles() {
      switch (this.$route.name) {
        case CREATING_RESUME_NAMES.STEP_1:
          return {
            next: "Continue",
          };
        case CREATING_RESUME_NAMES.STEP_2:
          return {
            next: "Continue",
          };
        case CREATING_RESUME_NAMES.STEP_3:
          return {
            next: "Continue",
          };
        case CREATING_RESUME_NAMES.STEP_4:
          return {
            next: "Continue",
          };
        case CREATING_RESUME_NAMES.STEP_5:
          return {
            next: "Finalize Resume",
          };
        case CREATING_COVER_LETTER_NAMES.STEP_1:
          return {
            next: "Continue",
          };
        case CREATING_COVER_LETTER_NAMES.STEP_2:
          return {
            next: "Finalize",
          };
        default:
          return {
            next: "Next",
          };
      }
    },
  },
  data() {
    return {
      isModalVisible: false,
      previewData: "",
      showButton: false,
    };
  },
  methods: {
    openChangeTemplateModal() {
      eventBus.$emit("openChangeTemplateModal");
    },
    openPreviewModal() {
      this.$emit("openPrevModal");
    },
    closePreviewModal() {
      this.$emit("closePrevModal");
    },
    goNextStep() {
      eventBus.$emit("nextStepResumeCreation");
    },
  },
};
</script>

<style lang="scss" scoped>
.inactive {
  z-index: 12 !important;
}
.creation-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 485px;
  display: flex;
  flex-direction: column;
  background-color: #fafafb;
  padding: 53px 53px 32px 53px;
  transition: all 0.3s ease;
  z-index: 13;
  justify-content: center;

  &__title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  &__text {
    margin-bottom: 32px;
    font-size: 14px;
    color: $light-gray;
    text-align: center;
  }
}

.creation-sidebar-inner {
  width: 100%;
  flex: 1;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: unset;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 8px;
    outline: none;
  }
}

.change-btn-wrap {
  text-align: center;
  margin: 0;

  @media (min-width: map_get($grid-breakpoints, md)) {
    margin: 32px 0 60px 0;
  }
}

.change-template-btn {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  color: $primary;
  font-weight: 500;
  font-size: 14px;

  svg {
    width: 24px;
    height: 24px;
    fill: $primary;
    margin-right: 16px;
  }

  &:hover {
    color: $body-color;

    svg {
      fill: $body-color;
    }
  }
}

.close-sidebar-btn {
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: $primary;
  border-radius: 50%;
  padding: 0;
  margin: 0;

  svg {
    fill: #fff;
    width: 12px;
    height: 12px;
  }

  &:hover {
    background-color: #fff;

    svg {
      fill: $primary;
    }
  }
}

@media (max-width: 991px) {
  .creation-sidebar {
    left: 0;
    right: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.5);
    z-index: 50;
    min-width: initial;
    max-width: initial;
    padding: 0;
    opacity: 0;
    transform: translateY(-101%);
    overflow: hidden;
    overflow-y: auto;

    &--opened {
      opacity: 1;
      transform: translateY(0);

      .creation-sidebar__title,
      .creation-sidebar__text {
        display: none;
      }

      .creation-sidebar-inner {
        text-align: center;
        padding: 24px;
        margin: 0 auto;
      }

      ::v-deep .progress {
        display: none;
      }

      .close-sidebar-btn {
        display: inline-flex;
      }
    }
  }
}

.creation-sidebar--opened {
  .creation-sidebar-inner {
    max-width: 400px;

    @media (min-width: map_get($grid-breakpoints, md)) {
      max-width: 550px;
    }
  }
}

.sidebar-nav {
  display: none;
  flex-direction: column;

  @media (min-width: map_get($grid-breakpoints, xl)) {
    display: flex;
    margin-top: 48px;
  }

  &__item {
    width: 100%;

    &--prev {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      padding: 0;
      margin-top: 24px;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      &:hover {
        color: $primary;

        svg {
          fill: $primary;
        }
      }
    }
  }
}

.sidebar-preview-wrap {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  width: 95%;
  margin-bottom: 20px;
  padding: 10px;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    width: 100%;
    height: 450px;
    background-color: transparent;
  }
}
button.previewbtn svg {
  width: 12px;
  padding: 0;
  vertical-align: middle;
  margin: 0;
}

button.previewbtn {
  z-index: 1;
  position: inherit;
  border: 1px solid #303030;
  border-radius: 30px;
  width: 75px;
  font-size: 11px;
  bottom: 7px;
  right: 7px;
  background: #fff;
  padding: 0;
  margin: 0;
  height: 22px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: bold;
  color: #585858;
  box-shadow: 0 0 10px -3px #a5a5a5;
}
.preview_btn_container {
  position: inherit;
  width: 100%;
  text-align: center;
  margin-top: -33px;
  z-index: 9;
}
.modal-backdrop {
  z-index: 9;
}
</style>
